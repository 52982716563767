import React from "react";
import { Link } from "gatsby";
import { useGoal } from "gatsby-plugin-fathom";

const BlockContact = (goalId) => {
  const handleGoal = useGoal(goalId);

  return (
    <section id="contact" className="block spacer p-top-xl">
      <div className="wrapper">
        <div className="text-center">
          <h4 className="mb-0 after">
            Interested in working together?{" "}
            <span className="line" style={{ whiteSpace: "nowrap" }}>
              Let's talk!
            </span>
          </h4>

          <div className="block spacer p-top-lg p-bottom-lg">
            <Link to="/contact">
              <button
                className="btn btn-dark btn-bold transform-scale-h border-0"
                onClick={handleGoal}
              >
                Get in touch
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlockContact;
