import React from "react";
import IconDataVis from "../../assets/icons/svg/icon_data_vis_black.svg";
import IconWebDev from "../../assets/icons/svg/icon_computer_black.svg";
import IconTools from "../../assets/icons/svg/icon_tools_black.svg";
import { Link } from "gatsby";
import { useGoal } from "gatsby-plugin-fathom";

const WhatIDo = ({ showTechBox = true, showMoreButton = false }) => {
  const handleGoal = useGoal("Q37L4TRN");

  return (
    <div id="my-experience" className="block spacer p-top-xl">
      <h2>
        What <span className="line">I can do</span> for you
      </h2>
      <div className="row gutter-width-lg with-pb-lg">
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <div className="card card-about">
            <div className="card-body p-large">
              <img
                className="img"
                src={IconDataVis}
                alt="Icon resembling data visualization, a rectangle with a bar chart, line chart and pie chart"
              />
              <div>
                <h4>
                  <span className="line">Data Visualization</span>
                </h4>
                <p>
                  I specialize in coding custom, interactive data visualizations
                  for the web. I use D3.js, React, Svelte and other tools to
                  create visualizations that help people understand complex
                  data.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
          <div className="card card-about">
            <div className="card-body p-large">
              <img
                className="img"
                src={IconWebDev}
                alt="Icon resembling a computer with a website opened"
              />
              <div>
                <h4>
                  <span className="line">Web Development</span>
                </h4>
                <p>
                  With a background as a Senior Frontend Developer, I create all
                  kinds of web products, like websites, tool plugins, web SaaS
                  products, and more. From the first rough prototype to the
                  final released and maintained version.
                </p>
              </div>
            </div>
          </div>
        </div>
        {showTechBox ? (
          <div className="col-xl-8 col-lg-10 col-md-12 col-sm-12 col-12 col-center">
            <div className="card card-about">
              <div className="card-body p-large">
                <img
                  className="img"
                  src={IconTools}
                  alt="Icon showing a gear and a counter-clockwise turning arrow"
                />
                <div>
                  <h4>Programming Languages, Tools and Techniques</h4>
                  <ul>
                    <li>
                      Frontend: JavaScript, React, Svelte, Vue, Gatsby, NextJS,
                      of course HTML & CSS.
                    </li>
                    <li>
                      Data Visualization: Mainly D3, sometimes Mapbox, QGIS.
                      NodeJS or Python for data processing.
                    </li>
                    <li>Hosting / Deployment: Netlify, Vercel or Heroku.</li>
                    <li>Design: Figma.</li>
                    <li>
                      Other: Databases, SQL, Content Management Systems, Git,
                      Trello, Slack, ...
                    </li>
                    <li>
                      How: With tech choices depending on the problem. With
                      strong stakeholder and expert involvement. With precise
                      scope and deliverables. With clear expectations and
                      content clients.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {showMoreButton ? (
        <div className="block spacer p-top-lg text-center">
          <Link to="/about">
            <button
              className="btn btn-outline-dark transform-scale-h"
              onClick={handleGoal}
            >
              Learn more about me
              <i className="icon-c icon-arrow-right" />
            </button>
          </Link>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default WhatIDo;
